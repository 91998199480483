import React, { useEffect, useState } from "react";
import lorena from './Lorena_5.png';
import { FaArrowRightLong } from "react-icons/fa6";

const POPUP_VIEW_KEY = "popup_last_seen";
const POPUP_EXPIRATION_MINUTES = 30;

function shouldShowPopup() {
  const lastSeen = localStorage.getItem(POPUP_VIEW_KEY);
  if (!lastSeen) {
    return true;
  }

  const lastSeenDate = new Date(parseInt(lastSeen, 10));
  const now = new Date();
  const diffInMinutes = (now - lastSeenDate) / (1000 * 60);

  return diffInMinutes >= POPUP_EXPIRATION_MINUTES;
}

const PopupSales = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    if (shouldShowPopup()) {
      setIsPopupVisible(true);
      localStorage.setItem(POPUP_VIEW_KEY, Date.now().toString());
    }
  }, []);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      {isPopupVisible && (
        <div className="fixed bg-black bg-opacity-60 w-full h-full z-50 flex justify-center items-center">
          <div className="relative bg-black w-[90%] p-[16px] lg:w-[500px] h-[430px] lg:h-[500px] rounded-[16px] text-center font-oswald">
            <h2 className="text-white text-[20px] lg:text-[32px] font-oswald font-extrabold">BLACK FRIDAY SALES</h2>
            <p className="text-white">IN PERIOADA <span className="foht-extrabold">08.11.2024-10.11.2024</span></p>
            <div className="flex mt-[16px]">
            <div className="flex  w-full lg:w-[70%] flex-col text-white space-y-2">
  <p className="text-[16px] lg:text-[20px]">AVANS CURS DE BAZA <br/><span className="font-extrabold text-[22px] lg:text-[30px]">500 <FaArrowRightLong className="inline-block" /> 200</span></p>
  <p  className="text-[16px] lg:text-[20px]">AVANS CURS DE EFECTE SPECIALE <br/><span className="font-extrabold text-[22px] lg:text-[30px]">500 <FaArrowRightLong className="inline-block" /> 300</span></p>
  <p  className="text-[16px] lg:text-[20px]">CURS VIP  <br/> DE BAZA<br /><span className="font-extrabold text-[22px] lg:text-[30px]">4000 <FaArrowRightLong className="inline-block" /> 3000</span><br />
 CURS VIP <br /> DE PERFECTIONARE <br />
  <span className="font-extrabold text-[22px] lg:text-[30px]">2500 <FaArrowRightLong className="inline-block" /> 2000</span>
  </p>
</div>
              <img src={lorena} className="absolute bottom-0 right-0 w-[110px] lg:w-[200px] h-auto" alt="Promotional" />
            </div>
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 bg-white rounded-full w-[25px] lg:w-[30px] h-[25px] lg:h-[30px] lg:text-[20px] font-extrabold"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupSales;
